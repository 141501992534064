<template>
  <div class="login">
    <div class="left"></div>

    <div class="right">
      <div class="right-content">
        <div class="title-desc">
          <h1 class="title">登录系统</h1>
          <p class="desc">使用账号登录</p>
        </div>

        <div class="input-item">
          <div class="input-desc">账号</div>
          <el-input
            class="username"
            v-model="username"
            placeholder="请输入账号"
          ></el-input>
        </div>

        <div class="input-item">
          <div class="input-desc">密码</div>
          <el-input
            class="password"
            placeholder="请输入密码"
            v-model="password"
            show-password
          ></el-input>
        </div>

        <el-button
          class="submit"
          type="primary"
          @click="login"
          :loading="loading"
        >
          登录
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Login',
  components: {},
  data() {
    return {
      loading: false,
      username: '',
      password: '',
    }
  },
  mounted() {
    document.title = '登录'
    this.username = sessionStorage.getItem('username') || ''
    this.password = sessionStorage.getItem('password') || ''
  },
  methods: {
    login() {
      this.loading = true
      //合理性验证
      let a = this.username.replace(/(^[ \t\n\r]*)|([ \t\n\r]*$)/g, '').length
      let b = this.password.replace(/(^[ \t\n\r]*)|([ \t\n\r]*$)/g, '').length
      if (!a) {
        this.loading = false
        this.$message.error('账号不能为空')
        return
      }
      if (!b) {
        this.loading = false
        this.$message.error('密码不能为空')
        return
      }
      if (this.username === 'lzfw' && this.password === 'lzfw') {
        //记住登录状态
        sessionStorage.setItem('username', this.username)
        sessionStorage.setItem('password', this.password)
        sessionStorage.setItem('login', 1)
        //跳转首页
        this.$message({
          message: '登录成功',
          type: 'success',
        })
        this.$router.replace('/')
      } else {
        this.loading = false
        this.$message({
          message: '登录失败',
          type: 'error',
        })
      }
    },
  },
}
</script>

<style lang="scss">
.login {
  width: 100%;
  height: 100%;
  display: flex;
  .left {
    width: 60%;
    height: 100%;
    background-image: url('../../assets/login/bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }

  .right {
    width: 40%;
    height: 100%;
    position: relative;
    .right-content {
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 28%;

      .title-desc {
        text-align: center;
        margin-bottom: 32px;
        .title {
          font-weight: 700;
        }
        .desc {
          margin-top: 8px;
          font-size: 12px;
          color: $tint-text-color;
        }
      }

      .input-item {
        margin-bottom: 16px;
        .input-desc {
          padding: 8px;
          font-size: 14px;
        }
        input {
          border-radius: $max-radius;
        }
      }

      .option-passsword {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 8px;
        .forget-password {
          font-size: 14px;
          cursor: pointer;
          text-decoration: underline;
          color: $theme-color;
        }
      }

      .submit {
        width: 100%;
        height: 58px;
        margin-top: 20px;
        border-radius: $max-radius;
      }
    }
  }
}
</style>
